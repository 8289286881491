<template>
    <div class="yieldManagement" style="height: calc(100% - 320px);">
        <div class="top-heaer">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark d-flex">
                        <div class="d-flex">
                            <div class="lable">
                                <!--<p>开始时间</p>-->
                            </div>
                            <div>
                                <el-date-picker v-model="selectForm.startTime"
                                                value-format="yyyy-MM-dd"
                                                type="date"
                                                :clearable="false"
                                                placeholder="选择开始日期" style="width:100%;">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                                <!--<p>结束时间</p>-->
                            </div>
                            <div>
                                <el-date-picker v-model="selectForm.endTime"
                                                value-format="yyyy-MM-dd"
                                                type="date"
                                                :clearable="false"
                                                placeholder="选择结束日期" style="width:100%;">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                                <!--<p>车间选择</p>-->
                            </div>
                            <div>
                                <el-select v-model="selectForm.workShopId" @change="getWorkShopId" placeholder="请选择车间">
                                    <el-option
                                            v-for="item in selectForm.workShopList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                                <!--<p>工序选择</p>-->
                            </div>
                            <div>
                                <el-select v-model="selectForm.processId" @change="getMachineList" placeholder="请选择工序">
                                    <el-option
                                            v-for="item in selectForm.processList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                                <!--<p>设备选择</p>-->
                            </div>
                            <div>
                                <el-select v-model="selectForm.machineId" clearable placeholder="请选择设备">
                                    <el-option
                                            v-for="item in selectForm.machineList"
                                            :key="item.id"
                                            :label="item.code"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                                <!--<p>班制选择</p>-->
                            </div>
                            <div>
                                <el-select v-model="selectForm.shiftId" clearable placeholder="请选择班制">
                                    <el-option
                                            v-for="item in selectForm.shiftList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 10px;">
                            <div class="lable">
                            </div>
                            <div>
                                <el-select v-model="selectForm.groupId" clearable placeholder="请选择班组">
                                    <el-option
                                            v-for="item in selectForm.groupList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <el-badge>
                            <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;"
                                 :title="$t('REFRESH')" @click="batch" src="/img/Bnt/refresh.png"/>
                        </el-badge>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="table" style="height: 100%">
            <el-table
                    :data="handoverForm.tempList"
                    border
                    ref="table"
                    height="145%"
                    style="width: 100%">
                <el-table-column
                        prop="workShopName"
                        label="车间"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="machineCode"
                        label="设备编号"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="shiftName"
                        label="班制"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="groupName"
                        label="班组"
                        align="center"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="startTime"
                        label="开始时间"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="endTime"
                        label="结束时间"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="startIotLength"
                        label="开始机台米数"
                        align="center"
                        width="160">
                    <template slot-scope="{row, $index}">
                        <el-input type="number" @change="getTempYield(row, $index)" v-model="row.startIotLength"/>
<!--                      {{row.startIotLength}} - {{$index}}-->
                    </template>
                </el-table-column>
                <el-table-column
                        prop="endIotLength"
                        label="结束机台米数"
                        align="center"
                        width="160">
                    <template slot-scope="scope">
                        <el-input type="number" @change="getTempYield(scope.row)" v-model="scope.row.endIotLength"/>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="actualLength"
                        label="总米数"
                        align="center"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="yield"
                        label="预估产量"
                        align="center"
                        width="130">
                    <template slot-scope="scope">
                                              <el-input @blur.native.capture="getTempYield(scope.row,scope.$index)"
                                  v-model="scope.row.forecastYield"/>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        align="center"
                        width="100">
                    <template slot-scope="scope">
                        <!--<el-button-->
                        <!--@click.native.prevent="deleteRow(scope.$index, list)"-->
                        <!--type="text"-->
                        <!--size="small">-->
                        <!--确认-->
                        <!--</el-button>-->
                        <el-button
                                @click.native.prevent="deleteRow(scope.$index, handoverForm.tempList)"
                                type="text"
                                size="small">
                            移除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button type="primary" style="float: right;margin-right:68px" @click="subMachineDateList"
                       class="bg7caff5 border-none">提交
            </el-button>
        </div>
    </div>
</template>

<script>
    import {
        getWorkShop,
        getProcess,
        getMachine,
        getShiftList,
        getGroupList,
        getForecastYield,
        getDifferenceLength,
        getShiftMachineDataList,
        commitMachineDateList,
    } from "@/api/monthlyYieldOfShift.js"

    export default {
        name: "yieldManagement",
        data() {
            return {
                rules: {
                    workIotLength: [
                        {required: true, message: '请输入上班机台米数', trigger: 'blur'},
                    ],
                    afterWorkIotLength: [
                        {required: true, message: '请输入下班机台米数', trigger: 'blur'},
                    ],
                    forecastYield: [
                        {required: true, message: '请输入预估产量', trigger: 'blur'},
                    ],
                    transferUserName: [
                        {required: true, message: '请选择交接人员', trigger: 'blur'},
                    ],
                },
                selectForm: {
                    startTime: '',
                    endTime: '',
                    workShopList: [],
                    processList: [],
                    machineList: [],
                    shiftList: [],
                    groupList: [],
                    machineId: '',
                    workShopId: '',
                    processId: '',
                    shiftId: '',
                    groupId: '',
                },
                handoverForm: {
                    tempList: [],
                    workShopId: '',
                    workShopName: '',
                    machineId: '',
                    machineCode: '',
                    machineName: '',
                    startTime: '',
                    endTime: '',
                    startYield: '',
                    startEnergy: '',
                    energy: '',
                    yield: '',
                    shiftId: '',
                    shiftName: '',
                    groupId: '',
                    groupName: '',
                    actualLength: '',
                    endIotLength: '',
                    startCumulativeLength: '',
                    startIotLength: '',
                    type: '',
                },


            }
        },
        created() {
            getWorkShop().then(res => {
                this.selectForm.startTime = this.fundate(-11);
                this.selectForm.endTime = this.fundate(0)
                this.selectForm.workShopList = res.data.data;
                this.selectForm.workShopId = this.selectForm.workShopList[0].id;
                getProcess(this.selectForm.workShopId).then(res => {
                    let list = res.data.data;
                    if (list == undefined || list.length <= 0) {
                        this.selectForm.processList = [];
                        this.selectForm.machineList = [];
                    } else {
                        this.selectForm.processList = res.data.data;
                        this.selectForm.processId = this.selectForm.processList[0].id;
                        getMachine(this.selectForm.workShopId, this.selectForm.processId).then(res => {
                            let list2 = res.data.data;
                            if (list2 == undefined || list2.length <= 0) {
                                this.selectForm.machineList = [];
                            } else {
                                this.selectForm.machineList = res.data.data;

                            }
                        });
                        getShiftList(this.selectForm.processId).then(res => {
                            let list3 = res.data.data;
                            if (list3 == undefined || list3.length <= 0) {
                                this.selectForm.shiftList = [];
                            } else {
                                this.selectForm.shiftList = res.data.data;
                            }
                        });
                    }
                });
                getGroupList(this.selectForm.workShopId).then(res => {
                    let list4 = res.data.data;
                    if (list4 == undefined || list4.length <= 0) {
                        this.selectForm.groupList = [];
                    } else {
                        this.selectForm.groupList = res.data.data;
                    }
                });

            });



        },
        methods: {
            fundate(aa) {
                let date1 = new Date();
                let date2 = new Date(date1);
                date2.setDate(date1.getDate() + aa);
                let time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
                return time2
            },
            getWorkShopId(val) {
                this.selectForm.workShopList.find(item => item.id == val)
                getProcess(val).then(res => {
                    let list = res.data.data;
                    if (list == undefined || list.length <= 0) {
                        this.selectForm.processId = '';
                        this.selectForm.processList = [];
                    } else {
                        this.selectForm.processList = res.data.data;
                    }
                })
            },
            getMachineList(val) {
                getMachine(this.selectForm.workShopId, val).then(res => {
                    let list = res.data.data;
                    if (list == undefined || list.length <= 0) {
                        this.selectForm.machineId = '';
                        this.selectForm.machineList = [];
                    } else {
                        this.selectForm.machineList = res.data.data;
                    }

                })
            },
            batch() {
                getShiftMachineDataList(this.selectForm.startTime+" 00:00:00", this.selectForm.endTime+" 00:00:00", this.selectForm.workShopId,this.selectForm.machineId,this.selectForm.shiftId,this.selectForm.groupId).then(res => {
                    let template = this.list = res.data.data;
                    this.handoverForm.tempList = [];
                    for (let templateKey in template) {
                        let obj1 = {
                            "id": template[templateKey].id,
                            "workShopId": template[templateKey].workShopId,
                            "workShopName": template[templateKey].workShopName,
                            "machineCode": template[templateKey].machineCode,
                            "machineId": template[templateKey].machineId,
                            "startYield": template[templateKey].startYield,
                            "yield": template[templateKey].yield,
                            "startEnergy": template[templateKey].startEnergy,
                            "energy": template[templateKey].energy,
                            "corporationId": template[templateKey].corporationId,
                            "startCumulativeLength": template[templateKey].startCumulativeLength,
                            "shiftId": template[templateKey].shiftId,
                            "shiftName": template[templateKey].shiftName,
                            "groupId": template[templateKey].groupId,
                            "groupName": template[templateKey].groupName,
                            "startTime": template[templateKey].startTime,
                            "endTime": template[templateKey].endTime,
                            "startIotLength": template[templateKey].startIotLength,
                            "endIotLength": template[templateKey].endIotLength,
                            "actualLength": template[templateKey].actualLength,
                            "type": template[templateKey].type,
                          forecastYield: template[templateKey].yield
                        }

                        this.handoverForm.tempList.push(obj1)
                    }
                })

            },
            getTempYield(row, index) {
                getForecastYield(row.machineId, row.startTime, row.startIotLength,
                     row.endIotLength,row.endTime, row.yield).then(res => {
                    row.forecastYield = res.data.data
                  this.$set(row, row.endIotLength, row)
                    this.$forceUpdate()
                });
                getDifferenceLength(row.startTime,row.machineId,row.startIotLength,
                    row.endIotLength,row.endTime).then(res => {
                    row.actualLength = res.data.data
                    this.$set(row, row.actualLength, row)
                    this.$forceUpdate()
                });

            },
            subMachineDateList() {
                let returnList = []
                this.handoverForm.tempList.forEach(function (item) {
                    let temp = {
                        "id": item.id,
                        "workShopId": item.workShopId,
                        "workShopName": item.workShopName,
                        "machineId": item.machineId,
                        "machineCode": item.machineCode,
                        "machineName": item.machineName,
                        "startTime": item.startTime,
                        "endTime": item.endTime,
                        "startYield": item.startYield,
                        "yield": item.yield,
                        "startEnergy": item.startEnergy,
                        "energy": item.energy,
                        "corporationId": item.corporationId,
                        "productCategoryId": item.productCategoryId,
                        "shiftId": item.shiftId,
                        "shiftName": item.shiftName,
                        "groupId": item.groupId,
                        "groupName": item.groupName,
                        "actualLength": item.actualLength,
                        "endIotLength": item.endIotLength,
                        "startCumulativeLength": item.startCumulativeLength,
                        "startIotLength": item.startIotLength,
                        "type": item.type,
                        "forecastYield": item.forecastYield,
                    }
                    returnList.push(temp)
                });
                commitMachineDateList(returnList).then(res => {
                    this.handoverForm.tempList = res.data.data
                    if (res.data.data.length > 0) {
                        this.$message({
                            message: "提交成功," + "有" + res.data.data.length + "数据有误，请查证！",
                            type: "success"
                        });
                    } else {
                        this.$message({
                            message: "提交成功",
                            type: "success"
                        });
                    }
                })
            },
            deleteRow(index, rows) {
                rows.splice(index, 1);
            },

            standAlone() {
                this.handoverForm.tempList = []
            }

        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-card__header {
        color: #FFFFFF;
        background-color: #aaa;
    }

    .blue {
        background-color: #1f94f4;
    }

    .yieldManagement {
        padding: 20px;
        background: rgba(245, 246, 248, 1);
        .border-none {
            border: none;
        }
        .bg7caff5 {
            background: #7CAFF5;
        }

        .top-heaer {
            background: #fff;
            padding: 10px;
            border-radius: 5px;
        }
        .lable {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .d-flex {
            display: flex;
        }

        .select-box {
            padding-left: 30px;
            height: 70px;
            border-bottom: 1px solid rgba(151, 151, 151, 1);
            margin-bottom: 30px;
            .btn-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 30px;
                .btn {
                    background: none;
                    color: rgba(0, 0, 0, .6);
                    border: none;
                }
                .btn-ac {
                    background: rgba(90, 155, 248, 1);
                    color: #fff;
                }
            }
        }
        .table {
            margin-top: 35px;
            /deep/ th {
                background: rgba(224, 226, 228, 1) !important;
                color: #000 !important;
            }
        }
    }
</style>
